// export const processData = (data) => {
//     const groupedData = {};
//     // Iterate over the data and group it by month
//     data.forEach((item) => {
//         const date = new Date(item.createdAt);
//         const month = date.toLocaleString("en-US", { month: "long" });
//         const year = date.getFullYear();

//         const key = `${month} ${year}`; // Create a unique key for each month

//         if (groupedData[key]) {
//             // Increment the total value by one for each occurrence
//             groupedData[key].Total += 1;
//         } else {
//             // Initialize the value for the month with 1
//             groupedData[key] = {
//                 name: key,
//                 Total: 1,
//             };
//         }
//     });

//     // Convert the groupedData object into an array
//     const processedData = Object.values(groupedData);

//     return processedData;
// };

// export const processData = (data) => {
//     const groupedData = {};

//     data.forEach((item) => {
//         const date = new Date(item.createdAt);
//         const weekStart = date.getDate();
//         const weekEnd = weekStart + 6; // Assuming weekStart is always a Monday
//         const month = date.toLocaleString("en-US", { month: "long" });
//         const year = date.getFullYear();

//         const key = `${weekStart}-${weekEnd} ${month} ${year}`; // Unique key for each week

//         if (groupedData[key]) {
//             groupedData[key].Total += 1;
//         } else {
//             groupedData[key] = {
//                 name: key,
//                 Total: 1,
//             };
//         }
//     });

//     const processedData = Object.values(groupedData);

//     return processedData;
// };

// export const processData = (data) => {
//     const groupedData = {};

//     data.forEach((item) => {
//         const date = new Date(item.createdAt);
//         const weekStart = date.getDate();
//         const weekEnd = weekStart + 6;
//         const month = date.toLocaleString("en-US", { month: "long" });
//         const year = date.getFullYear();

//         const key = `${weekStart}-${weekEnd} ${month} ${year}`;

//         if (groupedData[key]) {
//             groupedData[key].Total += 1;
//         } else {
//             groupedData[key] = {
//                 name: key,
//                 Total: 1,
//             };
//         }

//         // Handle month and year changes
//         const nextDate = new Date(date);
//         nextDate.setDate(nextDate.getDate() + 7); // Increment by 7 days

//         // Check if the next date is in a different month or year
//         if (nextDate.getMonth() !== date.getMonth() || nextDate.getFullYear() !== date.getFullYear()) {
//             const nextMonth = nextDate.toLocaleString("en-US", { month: "long" });
//             const nextYear = nextDate.getFullYear();

//             // Create a unique key for the next month and year
//             const nextKey = `1-${nextDate.getDate()} ${nextMonth} ${nextYear}`;

//             // Initialize the value for the next month and year if it doesn't exist
//             if (!groupedData[nextKey]) {
//                 groupedData[nextKey] = {
//                     name: nextKey,
//                     Total: 0,
//                 };
//             }
//         }
//     });

//     const processedData = Object.values(groupedData);

//     return processedData;
// };

export const processData = (data) => {
    const groupedData = {};

    data.forEach((item) => {
        const date = new Date(item.createdAt);
        const weekStart = date.getDate();
        let weekEnd = new Date(date);
        weekEnd.setDate(weekStart + 6); // Get the date 6 days later

        // If weekEnd falls in the next month, adjust it to the last day of the current month
        if (weekEnd.getMonth() !== date.getMonth()) {
            weekEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        }

        const month = date.toLocaleString("en-US", { month: "long" });
        const year = date.getFullYear();

        const key = `${weekStart}-${weekEnd.getDate()} ${month} ${year}`; // Unique key for each week

        if (groupedData[key]) {
            groupedData[key].Total += 1;
        } else {
            groupedData[key] = {
                name: key,
                Total: 1,
            };
        }
    });

    const processedData = Object.values(groupedData);

    return processedData;
};
