
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import List from "./pages/list/List";
import SingleUser from "./pages/single/SingleUser";
import SingleCommercialAd from "./pages/single/CommercialAd";
import SignleArtle from './pages/single/SingleArticle'
import SingleInterest from "./pages/single/SingleInterest";
import New from "./pages/new/New";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import { productInputs, userInputs } from "./formSource";
import "./style/dark.scss";
import { useContext, useEffect } from "react";
import { DarkModeContext } from "./context/darkModeContext";
import AuthWrapper from './components/auth/AuthWarpper';
import AllDealTable from "./components/table/allDealTable";
import AllArticleTable from "./components/table/allArticleTable";
import AllArticleTableShare from "./components/table/allArticleTableShare";
import AllPendTable from "./components/table/allPendingTable";
import AllReportArticle from "./components/table/allReportTableArticle";
import AllReportUser from "./components/table/allReportTableUser";
import Interest from "./pages/list/Interest";
import Notification from "./components/table/notification";
import Newsletter from "./components/table/newsletter";
import CommercialAd from "./components/table/commercialAd";
import Reset from "./pages/login/reset";
import AdminProfile from "./pages/single/adminProfile";
import SingleArticle from "./pages/single/SingleArticle";
import OTP from "./pages/login/Otp";
import Backup from "./pages/single/Backup";
import ArticleReport from "./pages/single/ArticleReport";
import UserReport from "./pages/single/UserReport";
import AllDealMoneyTable from "./components/table/allDealMoneyTable";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
function App() {
  const { darkMode } = useContext(DarkModeContext);

  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route index element={<Login />} />
          <Route path="/" element={<AuthWrapper />} >
            <Route path="home" element={<Home />} />
            <Route path="reset" element={<Reset />} />
            <Route path="otp" element={<OTP />} />
            <Route path="admin" element={<AdminProfile />} />
            <Route path="backup" element={<Backup />} />
            <Route path="notification" element={<Notification />} />
            <Route path="newsletter" element={<Newsletter />} />
            <Route path="shares" element={<AllArticleTableShare />} />
            <Route path="users" element={<List />}>
            </Route>
            <Route path="users/:userId" element={<SingleUser />} />
            <Route
              path="users/new"
              element={<New inputs={userInputs} title="Add New User" />}
            />

            <Route path="deal" element={<AllDealTable />}>
              <Route path=":userId" element={<SingleUser />} />
              <Route path=":articleId" element={<SingleArticle />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="dealMoney" element={<AllDealMoneyTable />}>
              <Route path=":userId" element={<SingleUser />} />
              <Route path=":articleId" element={<SingleArticle />} />
              <Route
                path="new"
                element={<New inputs={userInputs} title="Add New User" />}
              />
            </Route>
            <Route path="commercialAd" element={<CommercialAd />} >
            </Route>
            <Route path="commercialAd/:adId" element={<SingleCommercialAd />} />

            <Route path="article" element={<AllArticleTable />}>
              
            </Route>
            <Route path="article/:articleId" element={<SignleArtle />} />
            <Route path="pendingarticle" element={<AllPendTable />}>
            </Route>
            <Route path="pendingarticle/:articleId" element={<SignleArtle />} />

            <Route path="reportarticle" element={<AllReportArticle />}>
            </Route>
            <Route path="reportarticle/:reportId" element={<ArticleReport />} />
            <Route path="reportuser" element={<AllReportUser />}>
            </Route>
            <Route path="reportuser/:reportId" element={<UserReport />} />

            <Route path="interest" element={<Interest />}>
              <Route path=":productId" element={<SingleInterest />} />
              <Route
                path="new"
                element={<New inputs={productInputs} title="Add New Product" />}
              />
            </Route>
            {/* </AuthWrapper>}> */}
            {/* </Route> */}
          </Route>
        </Routes>
      </BrowserRouter>

    </div >
  );
}
{/* </Route> */ }

export default App;
