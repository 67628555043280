import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import EditFormField from "../../components/UI/EditFormField";
import { useEffect, useState, useRef } from "react";
import { ArticleColumns } from "../table/articletablesource";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
const CommercialAdTable = ({ userId }) => {
  const [data, setData] = useState([]);
  const [title, setTile] = useState("Commercial Ads");
  const [id, setId] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  //
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const [file, setFile] = useState("");
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [allInterests, setAllInterests] = useState([]);
  // const [editImages, setEditImages] = useState([]);
  const [newTitle, setNewTitle] = useState("");
  const [newCategory, setNewCategory] = useState([]);
  const [newLink, setNewLink] = useState("");
  // const [editCondition, setEditCondition] = useState("");
  const [newDescription, setNewDescription] = useState("");
  // const [shownImage, setShownImage] = useState([]);
  // const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [isMaxImages, setIsMaxImages] = useState(false);
  // const [visibleImages, setVisibleImages] = useState([]);
  // let visibleImages = [...editImages, ...selectedImages];

  const [selectedImages, setSelectedImages] = useState([]);
  const [fileList, setFileList] = useState([]);
  const handleAddClose = () => {
    setIsEditPopupOpen(false);
  };
  const handlePageChange = (newPage) => {
    if (newPage >= currentPage) {
      setCurrentPage(newPage + 1);
      fetchData({ page: newPage + 1 });
    }
  };
  const inputFile = useRef(null);

  useEffect(() => {
    if (selectedImages.length > 0) {
      return setIsMaxImages(true);
    }
    setIsMaxImages(false);
  }, [selectedImages]);

  const onImageClick = () => {
    inputFile.current.click();
  };
  const fetchInterests = async () => {
    const url = "https://cdn.tauschtakel.de/admin-interest/getAll";
    const response = await fetch(url);
    const data = await response.json();
    setAllInterests(data.data);
    setTimeout(() => {}, 3000);
  };
  const handleSubmit = async () => {
    // console.log(newCategory, typeof newCategory);
    if (selectedImages.length === 0) {
      return alert("Please select at least one image");
    }
    const uploadArray = await uploadImages();
    // // return;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("title", newTitle);
    urlencoded.append("description", newDescription);
    urlencoded.append("category", [...newCategory]);
    urlencoded.append("link", newLink);
    urlencoded.append("speed", "newLink");
    urlencoded.append("images", uploadArray);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-commercialAds/new", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // console.log(result, "response");
        // setIsEditPopupOpen(false);
        // fetchData();
        // setSelectedImages([]);
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.match(/image\/(png|jpg|jpeg)/gm)) {
      setFileList((oldFileList) => [...oldFileList, file]);
      setSelectedImages((oldImages) => [
        ...oldImages,
        URL.createObjectURL(file),
      ]);
    } else {
      alert("Selected file is not a valid image!");
    }
  };
  const handleEditImagesRemove = (image) => {
    setSelectedImages((old) => {
      return old.filter((element) => element !== image);
    });
  };
  const uploadImages = async () => {
    // alert();
    const data = new FormData();

    fileList.forEach((file, i) => {
      data.set("image", file, file.name);
    });
    // console.log(data, "pp");
    const response = await fetch(
      "https://cdn.tauschtakel.de/user-article/postImage",
      {
        method: "POST",
        body: data,
      }
    );
    const uploadArray = await response.json();
    return uploadArray.response;
  };
  //
  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id[0]);
    // console.log(id[0]);
  };
  const handleOpenUserPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log("------->",id);
  };
  const handleDeleteClosePopup1 = (data) => {
    console.log(totalRows, "dddddd");
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(
      "https://cdn.tauschtakel.de/admin-commercialAds/delete",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        // console.log("------->", result);
        // fetchData();
        window.location.reload();
        setIsDeletePopupOpen(false);
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const fetchData = async ({ page }) => {
    setCurrentPage((old) => {
      // console.log(old, "from func");
      return old++;
    });
    let url = `https://cdn.tauschtakel.de/admin-commercialAds/getAll?page=${page}`;
    // if (userId != null) {
    //   url = `https://cdn.tauschtakel.de/admin-article/userArticles?page=${1}&uid=${userId}`;
    // }
    // console.log(url);
    const response = await fetch(url);
    const data = await response.json();
    console.log(data, "rrrrr");
    setData((old) => {
      return [...old, ...data.data];
    });
    // setTotalRows(data.totalRows?);
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            <Link
              to={`/commercialAd/${params.row._id}`}
              style={{ textDecoration: "none", textAlign: "center" }}
            >
              <div style={{}} className="viewButton">
                View
              </div>
            </Link>
            <div
              className="deleteButton"
              style={{ textAlign: "center" }}
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteClick(params.row._id)}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    fetchData({ page: 1 });
    fetchInterests();
  }, []);
  const ArticleColumns = [
    {
      field: "title",
      headerName: "Title",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.images);
          // console.log("Title",params.row.image);
        };
        return (
          <div className="cellWithImg">
            {" "}
            <img
              className="cellImg"
              src={params.row.images[0]}
              alt="avatar"
              onClick={handleImageClick}
            />
            <Link
              to={`/commercialAd/${params.row._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.title}
            </Link>
          </div>
        );
      },
    },
    // {
    //   field: "Price",
    //   headerName: "Price",
    //   width: 110,
    //   renderCell: (params) => {
    //     return <div className="cellWithImg"> {params.row.price} </div>;
    //   },
    // },
    {
      field: "Category",
      headerName: "Category",
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg"> {params.row.category.join(", ")} </div>
        );
      },
    },
    {
      field: "Link",
      headerName: "Link",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.link} </div>;
      },
    },
    {
      field: "created",
      headerName: "Created At",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.createdAt}</div>;
      },
    },
  ];
  // const index = 1;
  // if (userId == null) {
  //   ArticleColumns.splice(index, 0, {
  //     field: "Username",
  //     headerName: "Username",
  //     width: 188,
  //     renderCell: (params) => {
  //       const handleImageClick1 = () => {
  //         handleOpenUserPopup(params.row.userData.image);
  //         // console.log("User",params.row.userData.image);
  //       };
  //       return (
  //         <div className="cellWithImg">
  //           <img
  //             className="cellImg"
  //             src={params.row.userData.image}
  //             alt="avatar"
  //             onClick={handleImageClick1}
  //           />
  //           <Link
  //             to={`/users/${params.row.userData._id}`}
  //             style={{ textDecoration: "none", color: "gray" }}
  //           >
  //             {params.row.userData.username}
  //           </Link>
  //         </div>
  //       );
  //     },
  //   });
  // }
  return (
    <div className="datatable">
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="datatableTitle">{title}</div>
        <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
          <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
            <div
              style={{
                background: "transparent",
                padding: "20px",
                borderRadius: "4px",
              }}
            >
              <img
                style={{
                  width: "100%",
                  maxHeight: "80vh",
                  objectFit: "contain",
                }}
                src={id}
                alt="avatar"
              />
              <button onClick={handleClosePopup}>Close</button>
            </div>
          </Popup>
        </div>

        <Popup open={isEditPopupOpen} onClose={handleAddClose} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "grid",
              position: "relative",
              // justifyItems: "right",
            }}
          >
            <div
              style={{
                marginBottom: "25px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "left",
                // border: "1px solid black",
                width: "100%",
              }}
            >
              <form
                style={{
                  margin: "15px",
                  marginTop: "0px",
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: "center",
                  justifyContent: "left",
                  // minWidth: "50%",
                  // border: "1px solid black",
                }}
              >
                <div
                  style={{
                    alignItems: "start",
                    // border: "1px solid black"
                  }}
                >
                  <h1 style={{ color: "gray" }}>Commercial Ad</h1>
                </div>
                <div>
                  <EditFormField
                    value={newTitle}
                    options={""}
                    type={"input"}
                    title={"Title"}
                    valueSetter={setNewTitle}
                  />
                  <EditFormField
                    value={newLink}
                    options={""}
                    type={"input"}
                    title={"Link"}
                    valueSetter={setNewLink}
                  />
                  <EditFormField
                    value={newCategory}
                    options={allInterests}
                    type={"radio"}
                    title={"Category"}
                    valueSetter={setNewCategory}
                  />
                </div>
              </form>

              <div
                style={{
                  maxWidth: "50%",
                  // border: "1px solid black",
                  paddingLeft: "50px",
                  minWidth: "50%",
                }}
              >
                {" "}
                <div className="detailItem ">
                  {/* <span
                    style={{
                      fontSize: 23,
                      fontWeight: "bold",
                      color: "gray",
                    }}
                  >
                    Picture
                  </span> */}
                  <div className="grid-container">
                    {selectedImages && selectedImages.length > 0 ? (
                      selectedImages.map((image, index) => {
                        const removeImage = () => {
                          // console.log(index, "bbbbbbb");
                          handleEditImagesRemove(image);
                        };
                        return (
                          <div key={index} style={{ position: "relative" }}>
                            <img
                              src={image}
                              alt={`Image ${index}`}
                              style={{
                                width: "80px",
                                height: "80px",
                                borderRadius: "10px",
                                marginRight: "10px",
                              }}
                            />
                            <span
                              style={{
                                position: "absolute",
                                marginTop: "5px",
                                right: "20px",
                              }}
                            >
                              <img
                                style={{ width: "20px", height: "20px" }}
                                src={require("../../components/Image/delete.png")}
                                onClick={removeImage}
                              />
                            </span>
                          </div>
                        );
                      })
                    ) : (
                      <p>No images selected.</p>
                    )}
                    {isMaxImages ? (
                      ""
                    ) : (
                      <>
                        <img
                          style={{
                            width: "80px",
                            position: "relative",
                          }}
                          src={require("../../components/Image/camera.png")}
                          onClick={onImageClick}
                        />
                        <input
                          type="file"
                          id="file"
                          ref={inputFile}
                          style={{ display: "none" }}
                          onChange={handleFileChange}
                        />
                      </>
                    )}
                  </div>
                </div>
                <EditFormField
                  value={newDescription}
                  options={""}
                  type={"textarea"}
                  title={"Description"}
                  valueSetter={setNewDescription}
                />
              </div>
            </div>
            <div
              style={{
                maxWidth: "60%",
                // border: "1px solid black",
                padding: "10px",
                position: "absolute",
                // left: "250px",
                right: "25px",
                bottom: "0px",
                display: "flex",
                gap: "10px",
              }}
            >
              <button
                onClick={() => {
                  setIsEditPopupOpen(false);
                }}
                type="button"
                style={{
                  backgroundColor: "transparent",
                  border: "1px solid",
                  borderColor: "#20ac73",
                  color: "inherit",
                }}
              >
                Close
              </button>
              <button onClick={handleSubmit} type="button">
                Submit
              </button>
            </div>
          </div>
        </Popup>
        <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
          <Popup
            open={isDeletePopupOpen}
            onClose={handleDeleteClosePopup1}
            modal
          >
            <div
              style={{
                background: "transparent",
                padding: "20px",
                borderRadius: "4px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  width: "70%",
                  height: "50px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <button
                  style={{
                    width: "200px",
                    backgroundColor: "crimson",
                    color: "white",
                  }}
                  onClick={() => handleDeleteClosePopup1("Yes")}
                >
                  Yes
                </button>
                <button
                  style={{ width: "200px" }}
                  onClick={() => handleDeleteClosePopup1("No")}
                >
                  No
                </button>
              </div>
            </div>
          </Popup>
        </div>

        <div className="AButton">
          <p
            style={{
              marginLeft: "15px",
              marginTop: "5px",
              position: "fixed",
              fontSize: "14px",
            }}
            onClick={() => setIsEditPopupOpen(true)}
          >
            Add New
          </p>
        </div>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={ArticleColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
        onPageChange={handlePageChange}
        rowCount={totalRows}
      />
    </div>
  );
};
export default CommercialAdTable;
