import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserReport = () => {
  const [reporter, setReporter] = useState();
  const [reported, setReported] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [createdAt, setCreatedAt] = useState();
  const [reportedImage, setReportedImage] = useState();
  const [id, setId] = useState();
  const { reportId } = useParams();
  useEffect(async () => {
    const url = `https://cdn.tauschtakel.de/admin-userReport/${reportId}`;
    console.log(url);

    const response = await fetch(url);

    const text = await response.text();
    let data = JSON.parse(text);

    setId(data._id);
    setReported(data.reportedData.username);
    setTitle(data.headline);
    setReporter(data.reporterData.username);
    setDescription(data.description);
    setCreatedAt(data.createdAt);
    setReportedImage(data.reportedData.image);
  }, [reportId]);
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {/* <div className="editButton">Edit</div> */}
            <h1 className="title">User Report {createdAt}</h1>
            <div className="item">
              {/* <img src={reportedImage} alt="" className="itemImg" /> */}
              <div className="details">
                <h1 className="itemTitle">{reporter} </h1>
                <div className="detailItem">
                  <span className="itemKey">Reported Article:</span>
                  <span className="itemValue">
                    {reported} <span className="itemValue"></span>
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Title:</span>
                  <span className="itemValue">
                    {title} <span className="itemValue"></span>
                  </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Description:</span>
                  <span className="itemValue">
                    {description} <span className="itemValue"></span>
                  </span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Created At:</span>
                  <span className="itemValue">
                    {createdAt} <span className="itemValue"></span>
                  </span>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserReport;
