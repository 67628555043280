import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { showLoadingModal } from "../../util/showLoadingModal";

const PendingArticleTable = () => {
  const [loadedRows, setLoadedRows] = useState([]);
  const [id, setId] = useState([]);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const handlePageChange = (newPage) => {
    if (newPage >= currentPage) {
      setCurrentPage(newPage + 1);
      fetchData({ page: newPage + 1 });
    }
  };
  const handleApprove = (id) => {
    showLoadingModal();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-article/approve", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // fetchData();
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const handleDeleteClosePopup1 = (data) => {
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id[0]);
    // console.log(id[0]);
  };
  const handleOpenUserPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log("------->",id);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-article/reject", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsDeletePopupOpen(false);
        // fetchData();
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  const fetchData = async ({ page }) => {
    setCurrentPage((old) => {
      console.log(old, "from func");
      return old++;
    });
    const url = `https://cdn.tauschtakel.de/admin-article/pendingArticles?page=${page}`;
    const response = await fetch(url);
    const data = await response.json();
    setLoadedRows((old) => {
      return [...old, ...data.articles];
    });
    setTotalRows(data.totalRows);
  };

  useEffect(() => {
    fetchData({ page: 1 });
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            <Link
              to={`/article/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div onClick={() => handleApprove(params.row._id)}>
              <div className="view1Button">Approve</div>
            </div>
            <div
              className="deleteButton"
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteClick(params.row)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Reject
            </div>
          </div>
        );
      },
    },
  ];

  const Penarticletablesource = [
    {
      field: "title",
      headerName: "Title",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.image);
        };
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image[0]} alt="avatar" />{" "} */}
            <img
              className="cellImg"
              src={params.row.image[0]}
              alt="avatar"
              onClick={handleImageClick}
            />
            <Link
              to={params.row._id}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.title}
            </Link>
          </div>
        );
      },
    },
    {
      field: "username",
      headerName: "Username",
      width: 188,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenUserPopup(params.row.userData.image);
          // console.log("User",params.row.userData.image);
        };
        return (
          // <div className="cellWithImg">
          //   {" "}
          //   {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
          //   <Link to={`/users/${params.row.uid}`}  style={{textDecoration: 'none', color:'gray'}}>
          //   {params.row.userData.username}{" "}
          //   </Link>
          // </div>
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.userData?.image}
              alt="avatar"
              onClick={handleImageClick1}
            />
            <Link
              to={`/users/${params.row.userData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.userData.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "Price",
      headerName: "Price",
      width: 110,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
            {params.row.price}{" "}
          </div>
        );
      },
    },
    {
      field: "Category",
      headerName: "Category",
      width: 150,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
            {params.row.category}{" "}
          </div>
        );
      },
    },
    {
      field: "Condition",
      headerName: "Condition",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
            {params.row.condition}{" "}
          </div>
        );
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
            {params.row.createdAt}{" "}
          </div>
        );
      },
    },
    {
      field: "Deleted Time",
      headerName: "Deleted Time",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}{" "}
            {params.row.timeRemaining}{" "}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">
        Pending Articles
        {/* <Link to="/users/new" className="link">
          Add New
        </Link> */}
      </div>
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "700px",
                objectFit: "contain",
              }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <DataGrid
        className="datagrid"
        rows={loadedRows}
        columns={Penarticletablesource.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
        onPageChange={handlePageChange}
        rowCount={totalRows}
      />
    </div>
  );
};

export default PendingArticleTable;
