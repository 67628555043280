import "../../pages/single/single.scss";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ShowNotificationTable from "./showNotificationTable";
import Swal from "sweetalert2";

const NotificationTable = () => {
  const { userId } = useParams();
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [receiver, setReceiver] = useState("All Users");
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");

  const getUsername = async () => {
    if (id) {
      async function getUser() {
        const userResponse = await fetch(
          `https://cdn.tauschtakel.de/user-user/${id}`
        );
        const data = await userResponse.json();
        return data.data.username;
      }
      console.log(await getUser());

      setReceiver(await getUser());
    } else {
      setReceiver("All Users");
    }
  };
  useEffect(() => {
    getUsername();
  }, [id]);

  const handleSubmit = (e) => {
    // alert(`${(message, title)}`);
    // e.preventDefault();
    if (!title || !message) {
      return Swal.fire({
        title: "Error",
        text: "Title and message cannot be empty",
        icon: "error",
      });
    }
    Swal.fire({
      title: "Please wait...",
      text: "The notification is being sent",
      icon: "success",
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("title", title);
    urlencoded.append("body", message);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    let url = "https://cdn.tauschtakel.de/admin-notification/sendNotification";
    if (id) {
      urlencoded.append("uid", id);
      url = "https://cdn.tauschtakel.de/admin-notification/notifyOne";
    }
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // handleClear();
        // alert("Notification sent successfully!");
        if (result.status === "success") {
          Swal.fire({
            title: "Success",
            text: "The notification has been sent",
            icon: "success",
          });
          document.body.addEventListener("click", () => {
            window.location.reload();
          });
        } else {
          Swal.fire({
            title: "Error",
            text: "User does not have an FCM token",
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleClear = () => {
    setMessage("");
    setTitle("");
  };
  return (
    <div className="single">
      <div className="singleContainer">
        <div className="top">
          <div className="left">
            <h1 className="title">Send Notification to {receiver}</h1>
            <div className="item">
              <form onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="title"
                    style={{ fontSize: "14px", color: "gray" }}
                  >
                    Title:
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    style={{ width: "604px" }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    style={{ fontSize: "14px", color: "gray" }}
                  >
                    Text:
                  </label>
                  <textarea
                    className="textarea"
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    style={{ width: "604px", height: "200px" }}
                  ></textarea>
                </div>
                <div style={{ display: "flex", alignSelf: "end" }}>
                  {/* <button
                    className="deleteButton"
                    onClick={handleClear}
                  >
                    Clear
                  </button> */}
                  <div className="cellAction">
                    <div
                      className="deleteButton"
                      onClick={handleClear}
                      style={{ textAlign: "center", padding: "6px" }}
                    >
                      Clear
                    </div>
                  </div>

                  <div className="cellAction">
                    <div
                      className="sendButton"
                      onClick={() => handleSubmit()}
                      style={{ textAlign: "center", padding: "6px" }}
                    >
                      Send
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bottom">
          <ShowNotificationTable />
        </div>
      </div>
    </div>
  );
};

export default NotificationTable;
