export const buttonStyle = {
  //   border: "2px solid #DE3163",
  //   color: "#DE3163",
  backgroundColor: "transparent",
  padding: "10px 10px",
  borderRadius: "5px",
  fontSize: "10px",
  fontWeight: "bold",
  width: "130px", // Custom width
  height: "30px", // Custom height
};
export const buttonStyle1 = {
  // border: "2px solid #40e0d0",
  // color: "#40e0d0",
  backgroundColor: "transparent",
  padding: "10px 20px",
  borderRadius: "5px",
  fontSize: "10px",
  fontWeight: "bold",

  width: "150px", // Custom width
  height: "30px", // Custom height
};
export const buttonStyle2 = {
  // border: "2px solid #FF5E0E",
  // color: "#FF5E0E",
  backgroundColor: "transparent",
  padding: "10px 20px",
  borderRadius: "5px",
  fontSize: "10px",
  fontWeight: "bold",

  width: "120px", // Custom width
  height: "30px", // Custom height
};
export const buttonStyle3 = {
  // border: "2px solid #32cd32",
  // color: "#32cd32",
  backgroundColor: "transparent",
  padding: "10px 20px",
  borderRadius: "5px",
  fontSize: "10px",
  fontWeight: "bold",

  width: "100px", // Custom width
  height: "30px", // Custom height
};
export const unApproveColor = {
  color: " #FFA500",
  border: "1px solid #FFA500",
};
export const buttonGroupStyle = {
  display: "flex",
  justifyContent: "center",
};
