import Swal from "sweetalert2";

export function showLoadingModal() {
    Swal.fire({
        title: "Please Wait!",
        text: "Waiting for the response from backend.",
        // html: "Waiting for the response from backend.",
        // timer: 2000,
        icon: "success",
        // didOpen: () => {
        //     Swal.showLoading();
        // }
    });
}
