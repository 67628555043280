import React, { useEffect, useState } from 'react';
import "./chart.scss";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ aspect, title }) => {
  const [data, setData] = useState([]);
  const url = `https://cdn.tauschtakel.de/admin-user/getAllStatic`;

  const addLeadingZero = (num) => num < 10 ? `0${num}` : num;

  useEffect(() => {
    fetch(url)
      .then(response => response.json())
      .then(data => {
        console.log('Data from API:', data);
        const processedData = processData(data.data);
        console.log('Processed data:', processedData);
        setData(processedData);
      })
      .catch(error => console.error('Error:', error));
  }, [url]);

const processData = (data) => {
  const counts = data.reduce((acc, user) => {
    const date = new Date(user.createdAt);
    const formattedDate = `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}`;
    if (!acc[formattedDate]) {
      acc[formattedDate] = { count: 1, date };
    } else {
      acc[formattedDate].count++;
    }
    return acc;
  }, {});

  const sortedData = Object.values(counts).sort((a, b) => a.date - b.date);

  return sortedData.map(({ date, count }) => ({ name: `${addLeadingZero(date.getDate())}.${addLeadingZero(date.getMonth() + 1)}`, Total: count }));
};

  return (
    <div className="chart">
      <div className="title">{title}</div>
      <ResponsiveContainer width="100%" height="100%" aspect={aspect}>
        <AreaChart
          width={730}
          height={250}
          data={data}
          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="total" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#20AC73" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#20AC73" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" stroke="gray" />
          <CartesianGrid strokeDasharray="3 3" className="chartGrid" />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="Total"
            stroke="#8884d8"
            fillOpacity={1}
            fill="url(#total)"
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;
