import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { dealColumns } from "../table/dealtablesource";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const DealMoneyDataTable = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  //
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };

  const handleDeleteClosePopup1 = (data) => {
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  //

  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-dealMoney/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsDeletePopupOpen(false);
        fetchData();
      })
      .catch((error) => console.log("error", error));
  };

  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log(id[0]);
  };
  const handleOpenUserPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log("------->",id);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const fetchData = async () => {
    const url = "https://cdn.tauschtakel.de/admin-dealMoney/allDeals";
    const response = await fetch(url);
    const data = await response.json();
    setData(data.data);
    console.log(data);
  };
  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            {/* <Link
              to={`/users/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link> */}
            <div
              className="deleteButton"
              onClick={() => handleDeleteClick()}
              style={{ textAlign: "center", padding: "3px" }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const dealColumns = [
    {
      field: "articlePerson",
      headerName: "Article Person",
      width: 200,
      sortable: true, // Hinzufügen dieser Zeile

      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenPopup(params.row.articlePersonData.image);
        };
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.articlePersonData.image}
              alt="avatar"
              onClick={handleImageClick1}
            />
            <Link
              to={`/users/${params.row.articlePersonData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.articlePersonData.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "sender",
      headerName: "Deal Sender",
      width: 200,
      sortable: true, // Hinzufügen dieser Zeile

      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenPopup(params.row.senderData.image);
        };
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.senderData.image}
              alt="avatar"
              onClick={handleImageClick1}
            />
            <Link
              to={`/users/${params.row.senderData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.senderData.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "article",
      headerName: "Article",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenPopup(params.row.articleData.image[0]);
        };
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.articleData.image[0]}
              alt="avatar"
              onClick={handleImageClick1}
            />
            <Link
              to={`/article/${params.row.articleData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.articleData.title}
            </Link>
          </div>
        );
      },
    },
    {
      field: "price",
      headerName: "Price",
      width: 150,
      sortable: true,
      valueGetter: (params) => Number(params.row.price),
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.price} </div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      sortable: true,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.status} </div>;
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      width: 200,
      sortable: true,
      valueGetter: (params) => {
        // const [day, month, yearHourMin] = params.row.createdAt.split(".");
        // const [year, hourMin] = yearHourMin.split(" ");
        // const [hour, min] = hourMin.split(":");
        // return new Date(year, month - 1, day, hour, min);
        return params.row.createdAt;
      },
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.createdAt} </div>;
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">Money Deals</div>
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "700px",
                objectFit: "contain",
              }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={dealColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default DealMoneyDataTable;
