import React from "react";
import MultiSelect from "react-multiselect-checkbox";

function EditFormField({ title, type, options, value, valueSetter }) {
  function handleValueChange(e) {
    // alert("");
    valueSetter(e.target.value);
    // console.log(value, "kkkkkkkk");
  }
  const handleOptionChange = (option) => {
    console.log(value, "jjj");
    if (value.includes(option)) {
      valueSetter(value.filter((item) => item !== option));
    } else {
      valueSetter([...value, option]);
    }
  };
  let inputElement = (
    <input onChange={handleValueChange} type="text" value={value} />
  );
  if (type === "option") {
    // console.log(options, "nnnnnnn");
    inputElement = (
      <select
        onChange={handleValueChange}
        style={{ marginLeft: "0px", width: "100%" }}
      >
        {options.map((one) => {
          let selected = false;
          if (one.title === value) {
            selected = true;
          }
          return (
            <option value={one.title} selected={selected}>
              {one.title}{" "}
            </option>
          );
        })}
      </select>
    );
  }
  if (type === "radio") {
    // console.log(value, "ssssssssss");
    inputElement = (
      <div className="radio-dropdown">
        <div className="grid-container-radio">
          {options.map((oneField) => (
            <div style={{ textAlign: "center" }} className="grid-item-radio">
              <label key={oneField.title} className="option">
                <input
                  type="checkbox"
                  checked={value.includes(oneField.title)}
                  onChange={() => handleOptionChange(oneField.title)}
                />
                {oneField.title}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (type === "textarea") {
    inputElement = (
      <textarea
        defaultValue={value}
        name=""
        id=""
        cols="30"
        rows="10"
        onChange={handleValueChange}
        style={{ width: "300px" }}
      ></textarea>
    );
  }
  return (
    <div
      style={{
        // margin: "14px",
        marginLeft: "0px",
        padding: "14px",
        paddingLeft: "0px",
        // border: "1px solid black",
        width: "100%",
      }}
    >
      <label
        htmlFor="title"
        style={{
          fontSize: "14px",
          color: "gray",
        }}
      >
        {title}:
      </label>
      {inputElement}
    </div>
  );
}

export default EditFormField;
