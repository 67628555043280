import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./home.scss";
import Widget from "../../components/widget/Widget";
import Featured from "../../components/featured/Featured";
import Chart1 from "../../components/chart1/Chart1";
import Chart2 from "../../components/chart2/Chart2";
import UList from "../../components/table/userTable";
import DealMoneyTable from "../../components/table/dealMoneyTable";

const Home = () => {
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="share" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div>
        <div className="charts">
          {/* <Featured /> */}
          <Chart1 title="Users Date (30 Days)" aspect={4 / 1} />
          <Chart2 title="Users Day Time (30 Days)" aspect={4 / 1} />
        </div>
        <div className="listContainer">
          <div className="listTitle">Exchange Deals</div>
          <UList />
        </div>
        <div className="listContainer">
          <div className="listTitle">Money Deals</div>
          <DealMoneyTable />
        </div>
      </div>
    </div>
  );
};

export default Home;
