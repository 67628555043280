import "./list.scss";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import Datatable from "../datatable/UserTable";
import ArticleTable from "../datatable/ArticleTableShare";

const AllArticleTable = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <ArticleTable />
      </div>
    </div>
  );
};

export default AllArticleTable;
