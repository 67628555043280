import "./navbar.scss";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import FullscreenExitOutlinedIcon from "@mui/icons-material/FullscreenExitOutlined";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ChatBubbleOutlineOutlinedIcon from "@mui/icons-material/ChatBubbleOutlineOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import { DarkModeContext } from "../../context/darkModeContext";
import { useContext, useEffect, useState } from "react";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import Popup from "reactjs-popup";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { Link } from "react-router-dom";
import EditFormField from "../../components/UI/EditFormField";

const Navbar = () => {
  const [image, setimage] = useState();
  const [userReports, setUserReports] = useState(0);
  const [articleReports, setArticleReports] = useState(0);
  const [isVersionPopupOpen, setIsVersionPopupOpen] = useState(false);
  const [version, setVersion] = useState("");
  const [build, setBuild] = useState("");
  const [editVersion, setEditVersion] = useState("");
  const [editBuild, setEditBuild] = useState("");

  const handleTextInput2Change = (event) => {
    setEditVersion(event.target.value);
  };

  const handleVersionClosePopup = async () => {
    setIsVersionPopupOpen(false);
  };

  function handleSubmitVersion() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("version", editVersion);
    urlencoded.append("build", editBuild);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-settings/update", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        if (data.status === "success") {
          // setIsVersionPopupOpen(false);
          window.location.reload();
        }
      })
      .catch((error) => console.log("error", error));
  }

  async function getVersionData() {
    const response = await fetch(
      "https://cdn.tauschtakel.de/admin-settings/getOne"
    );
    const data = await response.json();
    setVersion(data.data.version);
    setBuild(data.data.build);
    setEditVersion(data.data.version);
    setEditBuild(data.data.build);
    // console.log(data, "mhhhhhhhhhh");
  }

  const fetchUserReport = async () => {
    const url = "https://cdn.tauschtakel.de/admin-userReport/getAllCount";
    const response = await fetch(url);
    const data = await response.json();
    setUserReports(data.count);
  };

  const fetchArticleReport = async () => {
    const url = "https://cdn.tauschtakel.de/admin-report/allReportsCount";
    const response = await fetch(url);
    const data = await response.json();
    setArticleReports(data.count);
  };

  const adminData = async () => {
    const url = "https://cdn.tauschtakel.de/admin-admin/getCredentials";

    const response = await fetch(url);

    const text = await response.text();
    let data = JSON.parse(text);
    setimage(data.data.image);
    // console.log(data.data.image);
  };
  useEffect(() => {
    adminData();
    fetchArticleReport();
    fetchUserReport();
    getVersionData();
  }, []);

  const { dispatch } = useContext(DarkModeContext);

  return (
    <div className="navbar">
      <div className="wrapper">
        <div
          style={{
            // border: "1px solid",
            display: "flex",
            gap: "10px",
            padding: "5px",
            // paddingBottom: "0px",
          }}
        >
          <div>
            Version: <b style={{ color: "green" }}>{version}</b>
          </div>
          <div>
            Build: <b style={{ color: "green" }}>{build}</b>
          </div>
          <div
            onClick={() => {
              setIsVersionPopupOpen(true);
              // alert(isVersionPopupOpen);
            }}
            // style={{ marginLeft: "20px" }}
          >
            <div style={{ textDecoration: "underline" }} href="">
              {/* Edit */}
              <img
                style={{ width: "20px" }}
                src="https://cdn1.iconfinder.com/data/icons/social-messaging-ui-color/254000/52-512.png"
                alt="avatar"
                // onClick={handleImageClick}
              />
            </div>
          </div>
        </div>
        <div className="items">
          {/* <div className="item">
            <LanguageOutlinedIcon className="icon" />
            English
          </div>
          <div className="item">
            <DarkModeOutlinedIcon
              className="icon"
              onClick={() => dispatch({ type: "TOGGLE" })}
            />
          </div> */}
          <div className="item">
            {" "}
            <Link to="/reportuser">
              <PersonOutlineIcon
                style={{ fontSize: "22px" }}
                color="success"
                className="icon"
              />
              {userReports > 0 ? (
                <div className="counter">{userReports}</div>
              ) : (
                ""
              )}
            </Link>
          </div>
          <div className="item">
            <Link to="/reportarticle">
              <ChildFriendlyIcon
                color="success"
                className="icon"
                style={{ fontSize: "22px" }}
              />
              {articleReports > 0 ? (
                <div className="counter">{articleReports}</div>
              ) : (
                ""
              )}
            </Link>
          </div>
          <div className="item">
            <Link to={"/admin"}>
              <img src={image} alt="" className="avatar" />
            </Link>
          </div>
        </div>
      </div>

      <Popup open={isVersionPopupOpen} onClose={handleVersionClosePopup} modal>
        <div>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              // border: "1px solid",
            }}
          >
            <div
              style={{
                width: "97%",
              }}
            >
              <EditFormField
                value={editVersion}
                options={""}
                type={"input"}
                title={"Version"}
                valueSetter={setEditVersion}
              />
            </div>
            <div
              style={{
                width: "97%",
              }}
            >
              <EditFormField
                value={editBuild}
                options={""}
                type={"input"}
                title={"Build"}
                valueSetter={setEditBuild}
              />
              <br />
            </div>
            <button
              // style={{ border: "1px solid" }}
              onClick={handleSubmitVersion}
            >
              Update Settings
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default Navbar;
