import "./table.scss";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const DealMoneyTable = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const handleDeleteClick = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const handleDeleteClosePopup1 = (data) => {
    setIsDeletePopupOpen(false);
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-dealMoney/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsDeletePopupOpen(false);
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };

  const handleImageClick = (data) => {
    handleOpenPopup(data);
  };

  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  useEffect(async () => {
    const url = "https://cdn.tauschtakel.de/admin-dealMoney/allDeals";
    const response = await fetch(url);
    const jsonData = await response.json();
    setData(jsonData.data);
    console.log(jsonData, "alsakl");
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "700px",
                objectFit: "contain",
              }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">#</TableCell>
            <TableCell className="tableCell">Article Person</TableCell>
            <TableCell className="tableCell">Deal Sender</TableCell>
            <TableCell className="tableCell">Article</TableCell>
            <TableCell className="tableCell">Price</TableCell>
            <TableCell className="tableCell">Status</TableCell>
            <TableCell className="tableCell">Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row, index) => {
            function delDeal() {
              handleDeleteClick(row._id);
            }
            return (
              <TableRow key={row._id}>
                <TableCell className="tableCell">{index + 1}</TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img
                      className="image"
                      src={row.articlePersonData.image}
                      alt="avatar"
                      onClick={() =>
                        handleImageClick(row.articlePersonData.image)
                      }
                    />
                    <Link
                      to={`/users/${row.articlePersonData._id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.articlePersonData.username}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img
                      className="image"
                      src={row.senderData.image}
                      alt="avatar"
                      onClick={() => handleImageClick(row.senderData.image)}
                    />
                    <Link
                      to={`/users/${row.senderData._id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.senderData.username}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="tableCell">
                  <div className="cellWrapper">
                    <img
                      className="image"
                      src={row.articleData.image[0]}
                      alt="avatar"
                      onClick={() => handleImageClick(row.articleData.image[0])}
                    />
                    <Link
                      to={`/article/${row.articleData._id}`}
                      style={{
                        textDecoration: "none",
                        color: "gray",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {row.articleData.title}
                    </Link>
                  </div>
                </TableCell>
                <TableCell className="tableCell">{row.price}</TableCell>
                <TableCell className="tableCell">
                  <span className={"status"}>{row.status}</span>
                </TableCell>
                <TableCell className="tableCell">
                  {/* <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    style={buttonStyle2}
                    onClick={handleDeleteClick}
                  >
                    Delete
                  </Button> */}
                  <div
                    className="deleteButton"
                    // onClick={() => handleDelete(params.row._id)}
                    onClick={delDeal}
                    style={{ padding: "3px", textAlign: "center" }}
                  >
                    Delete
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "200px",
                backgroundColor: "crimson",
                color: "white",
              }}
              onClick={() => handleDeleteClosePopup1("Yes")}
            >
              Yes
            </button>
            <button
              style={{ width: "200px" }}
              onClick={() => handleDeleteClosePopup1("No")}
            >
              No
            </button>
          </div>
        </div>
      </Popup>
    </TableContainer>
  );
};

export default DealMoneyTable;
