import "./list.scss";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import ReportTable from "../datatable/ReportTableArticle";

const ReportTable1 = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <ReportTable />
      </div>
    </div>
  );
};

export default ReportTable1;
