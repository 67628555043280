import "../../pages/single/single.scss";
import { useState } from "react";
import Swal from "sweetalert2";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import NewsletterTable from "./NewsletterTable";

const NotificationTable = () => {
  const [subject, setSubject] = useState("");
  const [mailBody, setMailBody] = useState("");

  const handleSubmit = (e) => {
    // alert(`${(message, title)}`);
    // e.preventDefault();
    if (!subject || !mailBody) {
      return Swal.fire({
        title: "Error",
        text: "Title and message cannot be empty",
        icon: "error",
      });
    }
    Swal.fire({
      title: "Please wait...",
      text: "The newsletter is being sent",
      icon: "success",
    });
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("subject", subject);
    urlencoded.append("mailBody", mailBody);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    let url = "https://cdn.tauschtakel.de/admin-admin/sendNewsletter";
    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        // handleClear();
        // alert("Notification sent successfully!");
        if (result.status === "success") {
          Swal.fire({
            title: "Success",
            text: "The newsletter has been sent",
            icon: "success",
          });
          document.body.addEventListener("click", () => {
            window.location.reload();
          });
        } else {
          console.log(result);
          Swal.fire({
            title: "Error",
            text: "Newsletter could not be sent",
            icon: "error",
          });
        }
      })
      .catch((error) => console.log("error", error));
  };

  const handleClear = () => {
    setMailBody("");
    setSubject("");
  };
  return (
    <div className="single">
      <div className="singleContainer">
        <div className="top">
          <div className="left">
            <h1 className="title">Send Newsletter to all Users</h1>
            <div className="item">
              <form onSubmit={handleSubmit}>
                <div>
                  <label
                    htmlFor="title"
                    style={{ fontSize: "14px", color: "gray" }}
                  >
                    Title:
                  </label>
                  <input
                    type="text"
                    id="title"
                    value={subject}
                    onChange={(e) => setSubject(e.target.value)}
                    style={{ width: "604px" }}
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    style={{ fontSize: "14px", color: "gray" }}
                  >
                    Text:
                  </label>

                  <CKEditor
                    editor={ClassicEditor}
                    data=""
                    onReady={(editor) => {
                      // Allows you to store the editor instance and use it later.
                      editor.editing.view.change((writer) => {
                        writer.setStyle(
                          "height",
                          "200px",
                          editor.editing.view.document.getRoot()
                        );
                      });
                    }}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      // console.log({ event, editor, data });
                      setMailBody(data);
                    }}
                    config={{
                      simpleUpload: {
                        uploadUrl:
                          "https://myserver.herokuapp.com/image-upload",
                      },
                      toolbar: [
                        "heading",
                        "|",
                        "bold",
                        "italic",
                        "blockQuote",
                        "link",
                        "numberedList",
                        "bulletedList",
                        // "imageUpload",
                        "insertTable",
                        "tableColumn",
                        "tableRow",
                        "mergeTableCells",
                        // "mediaEmbed",
                        "|",
                        "undo",
                        "redo",
                      ],
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignSelf: "end",
                    marginTop: "5px",
                  }}
                >
                  <div className="cellAction">
                    <div
                      className="deleteButton"
                      onClick={handleClear}
                      style={{ textAlign: "center", padding: "6px" }}
                    >
                      Clear
                    </div>
                  </div>

                  <div className="cellAction">
                    <div
                      className="sendButton"
                      onClick={() => handleSubmit()}
                      style={{ textAlign: "center", padding: "6px" }}
                    >
                      Send
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="bottom">
          <NewsletterTable />
        </div>
      </div>
    </div>
  );
};

export default NotificationTable;
