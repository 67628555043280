import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { dealColumns } from "../table/dealtablesource";
import Popup from "reactjs-popup";
import { InterestColumns } from "../table/interesttablesource";
// import { InterestNotification } from "../table/interestNotification";

const NewsletterTable = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("recordId", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch(
      "https://cdn.tauschtakel.de/admin-admin/deleteNewsletter",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteClick = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const handleDeleteClosePopup1 = (data) => {
    setIsDeletePopupOpen(false);
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };

  const fetchData = async () => {
    const url = "https://cdn.tauschtakel.de/admin-admin/getAllNewsletters";
    const response = await fetch(url);
    const data = await response.json();
    setData(data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        function deleteAction() {
          handleDeleteClick(params.row._id);
        }
        return (
          <div className="cellAction">
            {/* <Link to={`/interest/${params.row._id}`} style={{ textDecoration: "none" }}>
              <div className="viewButton">View</div>
            </Link> */}
            <div
              className="deleteButton"
              onClick={deleteAction}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];
  const NotificationArray = [
    {
      field: "Title",
      headerName: "Title",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className="cellWithImg">
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}
            {params.row.subject}
          </div>
        );
      },
    },
    {
      field: "Text",
      headerName: "Text",
      width: 400,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        // console.log("--------->",params.row);
        return (
          <div className="cellWithImg">
            {/* <img className="cellImg" src={params.row.image} alt="avatar" /> */}
            {params.row.mailBody}
          </div>
        );
      },
    },
    {
      field: "CreatedAt",
      headerName: "Created at",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div className={`cellWithImg ${params.row.status}`}>
            {params.row.createdAt}
          </div>
        );
      },
    },
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">History of Newsletters</div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={NotificationArray.concat(actionColumn)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row._id}
      />

      <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "200px",
                backgroundColor: "crimson",
                color: "white",
              }}
              onClick={() => handleDeleteClosePopup1("Yes")}
            >
              Yes
            </button>
            <button
              style={{ width: "200px" }}
              onClick={() => handleDeleteClosePopup1("No")}
            >
              No
            </button>
          </div>
        </div>
      </Popup>

      <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
          }}
        >
          <img
            style={{
              width: "100%",
              maxHeight: "70vh",
              objectFit: "contain",
            }}
            src={id}
            alt="avatar"
          />
          <button onClick={handleClosePopup}>Close</button>
        </div>
      </Popup>
    </div>
  );
};

export default NewsletterTable;
