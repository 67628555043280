import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const InsertDataTable = () => {
  const [data, setData] = useState([]);
  const [textInput1, setTextInput1] = useState("");
  const [textInput2, setTextInput2] = useState("");
  const [sortNumber, setSortNumber] = useState("");
  const [id, setId] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isAddPopupOpen, setIsAddPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  const handleOpenPopup = (id) => {
    const row = data.find((item) => item._id === id);
    if (row) {
      setTextInput1(row.title);
      setSortNumber(row.sortNumber);
      setId(id);
      setIsPopupOpen(true);
    }
  };

  const handleOpenPopupAdd = () => {
    setIsAddPopupOpen(true);
  };

  const handleDeleteClosePopup1 = (data) => {
    if (data === "Yes") {
      handleDeleteClosePopup();
    } else {
      setIsDeletePopupOpen(false);
    }
  };

  const handleAddClosePopup1 = () => {
    setIsAddPopupOpen(false);
  };

  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };

  const handleDeleteClosePopup = () => {
    handleDelete(id);
  };

  const handleClosePopup = () => {
    if (!textInput1) {
      setIsPopupOpen(false);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("title", textInput1);
      urlencoded.append("sortNumber", sortNumber);
      urlencoded.append("id", id);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://cdn.tauschtakel.de/admin-interest/edit", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          let data = JSON.parse(result);
          if (data.status === "success") {
            setIsPopupOpen(false);
            setTextInput1("");
            setSortNumber("");
            fetchData();
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleAddClosePopup = () => {
    if (!textInput2) {
      setIsAddPopupOpen(false);
    } else {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("title", textInput2);

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: urlencoded,
        redirect: "follow",
      };

      fetch("https://cdn.tauschtakel.de/admin-interest/new", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          fetchData();
          setIsAddPopupOpen(false);
        })
        .catch((error) => console.log("error", error));
    }
  };

  const handleDelete = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-interest/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        setIsDeletePopupOpen(false);
        fetchData();
      })
      .catch((error) => console.log("error", error));
  };

  const handleEdit = (id) => {
    handleOpenPopup(id);
  };

  const handleAdd = () => {
    if (!isPopupOpen && !isDeletePopupOpen) {
      handleOpenPopupAdd();
    }
  };

  const handleTextInput1Change = (event) => {
    setTextInput1(event.target.value);
  };

  const handleTextInput2Change = (event) => {
    setTextInput2(event.target.value);
  };

  const handleSortNumberChange = (event) => {
    setSortNumber(event.target.value);
  };

  const fetchData = async () => {
    const url = "https://cdn.tauschtakel.de/admin-interest/getAll";
    const response = await fetch(url);
    const data = await response.json();
    setData(data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            <div
              className="viewButton"
              onClick={() => handleEdit(params.row._id)}
            >
              Edit
            </div>
            <div
              className="deleteButton"
              onClick={() => handleDeleteClick(params.row._id)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Delete
            </div>
          </div>
        );
      },
    },
  ];

  const sortedColumns = [
    {
      field: "sortNumber",
      headerName: "Nr",
      width: 30,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "title",
      headerName: "Title",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
    },
  ];

  const columns = sortedColumns.concat(actionColumn);

  return (
    <div className="datatable">
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                // width: "60%",
                padding: "20px",
                paddingTop: "0px",
                paddingBottom: "0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                value={sortNumber}
                onChange={handleSortNumberChange}
                placeholder="Sort Number"
              />
              <br />
              <input
                type="text"
                value={textInput1}
                onChange={handleTextInput1Change}
                placeholder="Title"
                // style={{ width: "1100px" }}
              />
              <br />
              <button
                onClick={handleClosePopup}
                // style={{ width: "1120px" }}
              >
                Update Settings
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isAddPopupOpen ? "none" : "auto" }}>
        <Popup open={isAddPopupOpen} onClose={handleAddClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <div
              style={{
                // width: "60%",
                padding: "20px",
                paddingTop: "0px",
                paddingBottom: "0px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                value={textInput2}
                onChange={handleTextInput2Change}
                placeholder="Title"
                // style={{ width: "1100px" }}
              />
              <br />
            </div>
            <button
              onClick={handleAddClosePopup}
              // style={{ width: "1120px" }}
            >
              Add Interest
            </button>
          </div>
        </Popup>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="datatableTitle">Interest</div>

        <div className="AButton" onClick={handleAdd}>
          <p
            style={{
              marginLeft: "15px",
              marginTop: "5px",
              position: "fixed",
              fontSize: "14px",
            }}
          >
            Add New
          </p>
        </div>
      </div>

      <DataGrid
        className="datagrid"
        rows={data}
        columns={columns}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
      />
    </div>
  );
};

export default InsertDataTable;
