import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import HandshakeIcon from '@mui/icons-material/Handshake';
import ShareIcon from '@mui/icons-material/Share';


const Widget = ({ type }) => {
  const [data, setData] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      const url = "https://cdn.tauschtakel.de/admin-admin/meta";
      const response = await fetch(url);
      const data = await response.json();
      setData(data);
    };

    fetchData();
  }, []);

  const widgetData = {
    user: {
      title: "USERS",
      isMoney: false,
      link: "/users",
      //linkText: "View all Users",
      icon: (
        <Link to="/users">
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
              fontSize: "90px",
            }}
          />
        </Link>
      ),
      getData: () => data?.users,
    },
    order: {
      title: "DEALS",
      isMoney: false,
      //link: "/deal",
      //linkText: "View all Deals",
      icon: (
        <HandshakeIcon
          className="icon"
          style={{
            backgroundColor: "rgba(218, 165, 32, 0.2)",
            color: "goldenrod",
            fontSize: "90px",
          }}
        />
   
    ),
      getData: () => data?.deals,
    },

    share: {
  title: "SHARES",
  isMoney: false,
  link: "/shares", 
  icon: (
    <Link to="/shares">
          <ShareIcon
        className="icon"
        style={{
          backgroundColor: "rgba(173, 216, 230, 0.2)",
          color: "blue",
          fontSize: "80px",
        }}
      />
  </Link>
  ),
  getData: () => data?.shares, // Ändern der Datenquelle auf "shares"
},



    earning: {
      title: "ALL ARTICLES",
      isMoney: true,
      link: "/article",
      //linkText: "View all Articles",
      icon: (
        <Link to="/article">
        <ChildFriendlyIcon
          className="icon"
          style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green", fontSize: "90px",
        }}
      />
    </Link>
  ),
      getData: () => data?.articles,
    },
    balance: {
      title: "PENDING ARTICLES",
      isMoney: true,
      link: "/pendingarticle",
      //linkText: "View all Pendings",
      icon: (
        <Link to="/pendingarticle">
        <HourglassTopIcon
          className="icon"
          style={{
            backgroundColor: "rgba(128, 0, 128, 0.2)",
            color: "purple",
            fontSize: "90px",
          }}
        />
      </Link>
    ),
      getData: () => data?.pendingArticles,
    },
  };

  const widget = widgetData[type];

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{widget.title}</span>
        <span className="counter">{widget.getData()}</span>
        <Link
          className="link"
          to={widget.link}
        >
          {widget.linkText}
        </Link>
      </div>
      <div className="right">
        {widget.icon}
      </div>
    </div>
  );
};

export default Widget;
