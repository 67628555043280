import "./list.scss";
import Sidebar from "../sidebar/Sidebar";
import Navbar from "../navbar/Navbar";
import NewsletterTable from "../datatable/newsletter";

const Newsletter = () => {
  return (
    <div className="list">
      <Sidebar />
      <div className="listContainer">
        <Navbar />
        <NewsletterTable />
      </div>
    </div>
  );
};

export default Newsletter;
