import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { useNavigate } from "react-router-dom";

const Datatable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);

  //
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const handlePageChange = (newPage) => {
    if (newPage >= currentPage) {
      setCurrentPage(newPage + 1);
      fetchData({ page: newPage + 1 });
    }
  };
  //

  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
  };
  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleDeleteClosePopup = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-user/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };

  const handleDeleteClosePopup1 = (data) => {
    if (data === "Yes") {
      handleDeleteClosePopup();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleDelete = (id) => {
    console.log("--->", id);
    //     var myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    // var urlencoded = new URLSearchParams();
    // urlencoded.append("uid", id);

    // var requestOptions = {
    //   method: 'POST',
    //   headers: myHeaders,
    //   body: urlencoded,
    //   redirect: 'follow'
    // };

    // fetch("https://cdn.tauschtakel.de/admin-user/delete", requestOptions)
    //   .then(response => response.text())
    //   .then(result => {
    //     fetchData();
    //     setIsPopupOpen(false)
    //   })
    //   .catch(error => console.log('error', error));
  };

  const handleApproveUser = (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-user/approveUser", requestOptions)
      .then((response) => response.text())
      .then((result) => fetchData())
      .catch((error) => console.log("error", error));
  };

  const fetchData = async ({ page }) => {
    const url = `https://cdn.tauschtakel.de/admin-user/getAll?page=${page}`;
    // console.log(url);
    const response = await fetch(url);
    const data = await response.json();
    // console.log(data.data);
    setTotalRows(data.totalRows);
    setData((old) => {
      return [...old, ...data.data];
    });
  };
  useEffect(() => {
    fetchData({ page: 1 });
  }, []);
  

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,

  disableColumnMenu: true,
  sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            <Link
              to={`/users/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteClick(params.row._id)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Delete
            </div>
            {params.row.verified === false ? (
              <div
                className="ABCutton"
                onClick={() => handleApproveUser(params.row._id)}
              >
                Approve
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const userColumns = [
    // { field: "id", headerName: "ID", width: 70 },
    {
      field: "user",
      headerName: "User",
      width: 230,
      sortable: true,
      valueGetter: (params) => params.row.username, //NEW!
      sortComparator: (v1, v2) => v1.localeCompare(v2),//NEW!
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.image);
        };
        return (
          <div className="cellWithStatus cellWithImg">
            <img
              className="cellImg"
              src={params.row.image}
              alt="avatar"
              onClick={handleImageClick}
            />

            <Link
              to={`/users/${params.row._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      width: 230,
      sortable: true,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.email}
          </div>
        );
      },
    },
    {
      field: "Created At",
      headerName: "Created At",
      width: 200,
      sortable: true,
      valueGetter: (params) => {
        const parts = params.row.createdAt.split(".");
        const date = new Date(`${parts[1]}.${parts[0]}.${parts[2]}`);
        return date.getTime();
      },
      sortComparator: (v1, v2) => v1 - v2,
      renderCell: (params) => {
        return (
          <div className={`cellWithStatus ${params.row.status}`}>
            {params.row.createdAt}
          </div>
        );
      },
    }
  ];

  return (
    <div className="datatable">
      <div className="datatableTitle">User</div>
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{
                width: "100%",
                maxHeight: "700px",
                objectFit: "contain",
              }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
      <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={userColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
        onPageChange={handlePageChange}
        rowCount={totalRows}
      />
    </div>
  );
};

export default Datatable;
