import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import List from "../../components/table/Table";
import UList from "../../components/table/userTable";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import InsertDataTable from "../../components/datatable/InsertTable";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArticleTabe from "../../components/datatable/ArticleTable";
import Popup from "reactjs-popup";
import EditFormField from "../../components/UI/EditFormField";
import {
  buttonGroupStyle,
  buttonStyle,
  buttonStyle1,
  buttonStyle2,
  buttonStyle3,
  unApproveColor,
} from "./Styles_button";

const SingleUser = () => {
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [editName, setEditName] = useState("");
  const [editPassword, setEditPassword] = useState("");
  const [email, setEmail] = useState("");
  const [userLocation, setUserLocation] = useState("");
  const [image, setImage] = useState("");
  const [userVerified, setUserVerified] = useState(false);
  const [articlesOnline, setArticlesOnline] = useState(0);
  const [createdAt, setCreatedAt] = useState(0);
  const [userInterests, setUserInterests] = useState([]);
  const [id, setId] = useState("");
  const [chartData, setChartData] = useState([]);
  const { userId } = useParams();
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [isPasswordPopupOpen, setIsPasswordPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [moneydeal, setMoneydeal] = useState(0);
  const [changedeal, setChangedeal] = useState(0);
  const [points, setPoints] = useState(0);
  const [report, setReport] = useState(0);


  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch("https://cdn.tauschtakel.de/admin-user/delete", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        navigate("/users");
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeleteClick = () => {
    setIsDeletePopupOpen(true);
  };
  const handleDeleteClosePopup1 = (data) => {
    setIsDeletePopupOpen(false);
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };

  const handlePasswordOpen = () => {
    setIsPasswordPopupOpen(true);
  };

  const handleEditOpen = () => {
    setIsEditPopupOpen(true);
  };

  const handleEditClose = () => {
    setIsEditPopupOpen(false);
  };

  const handlePasswordClose = () => {
    setIsEditPopupOpen(false);
  };
  const handleUnapprove = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-user/unApproveUser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchData();
      })
      .catch((error) => console.log("error", error));
  };
  const handleApprove = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-user/approveUser", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchData();
      })
      .catch((error) => console.log("error", error));
  };

  const handlePassword = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);
    urlencoded.append("password", editPassword);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-user/resetPassword", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchData();
        handlePasswordClose();
      })
      .catch((error) => console.log("error", error));
  };

  const handleEdit = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);
    urlencoded.append("username", editName);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/user-user/editUsername", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        fetchData();
        handleEditClose();
      })
      .catch((error) => console.log("error", error));
  };

  const fetchChartData = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("uid", userId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://cdn.tauschtakel.de/admin-article/articleFrequency",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // getData();
        setChartData(result.data);
        // console.log(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const fetchData = async () => {
    const url = `https://cdn.tauschtakel.de/admin-user/${userId}`;
    console.log(url);

    const response = await fetch(url);

    const text = await response.text();
    let data = JSON.parse(text);

    setId(data.user._id);
    setEmail(data.user.email);
    setUserLocation(data.user.userLocation);
    setImage(data.user.image);
    setName(data.user.username);
    setUserInterests(data.user.interests);
    setArticlesOnline(data.user.articles);
    setCreatedAt(data.user.createdAt);
    setEditName(data.user.username);
    setUserVerified(data.user.verified);
    setChangedeal(data.user.exchangeDealCount);
    setMoneydeal(data.user.moneyDealCount);
    setPoints(data.user.userPoints);
    setReport(data.user.reportCount);
  };

  useEffect(() => {
    fetchData();
    fetchChartData();
  }, [userId]);

  const sendNotification = () => {
    navigate(`/notification?id=${id}`);
  };
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            {/* <div onClick={handleEditOpen} className="editButton">
              Edit
            </div> */}
            <h1 className="title">User Information</h1>
            <div className="item">
              <img src={image} alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">{name} </h1>
                <div className="detailItem">
                  <span className="itemKey">Email:</span>
                  <span className="itemValue">{email} </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{userLocation} </span>
                </div>
                <div style={{ height: "20px" }}></div>
                <div className="detailItem">
                  <span className="itemKey">User Since:</span>
                  <span className="itemValue">{createdAt}</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Zip Code:</span>
                  <span className="itemValue">23 </span>
                </div> */}
                <div className="detailItem">
                  <span className="itemKey">Article Online:</span>
                  <span className="itemValue">{articlesOnline}</span>
                </div>
                <div style={{ height: "20px" }}></div>
                <div className="detailContainer" style={{ display: 'flex', justifyContent: 'space-between', width: '350px' }}>
    <div className="detailItem">
        <span className="itemKey">Points: </span>
        <span className="itemValue">
            {points} <span className="itemValue"></span>
        </span>
    </div>
    <div className="detailItem">
        <span className="itemKey">Money Deals: </span>
        <span className="itemValue">
            {moneydeal} <span className="itemValue"></span>
        </span>
    </div>
</div>
<div className="detailContainer" style={{ display: 'flex', justifyContent: 'space-between', width: '350px' }}>
    <div className="detailItem">
        <span className="itemKey">Report: </span>
        <span className="itemValue">
            {report} <span className="itemValue"></span>
        </span>
    </div>
    <div className="detailItem">
        <span className="itemKey">Exchange Deals: </span>
        <span className="itemValue">
            {changedeal} <span className="itemValue"></span>
        </span>
    </div>
</div>
                <div style={{ height: "20px" }}></div>
                <div className="detailItem">
                  <span className="itemKey">Interest Selected:</span>
                  <span className="itemValue">{userInterests?.length}</span>
                </div>
                <div className="grid-container">
                  {userInterests?.map((one, index) => {
                    if (!one?.title) return null;
                    return <div className="grid-item">{one?.title}</div>;
                  })}
                </div>
                <div style={{ height: "20px" }}></div>

                {/* Button Section   */}
                <div style={buttonGroupStyle}>
                  <Button
                    variant="outlined"
                    color="primary"
                    size="small"
                    style={buttonStyle}
                    onClick={handlePasswordOpen}
                  >
                    Password Reset
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  {/* Add space between buttons */}
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    style={buttonStyle1}
                    onClick={handleEditOpen}
                  >
                    Edit Username
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  <Button
                    variant="outlined"
                    color="warning"
                    size="small"
                    style={buttonStyle1}
                    onClick={sendNotification}
                  >
                    Send Notification
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  {/* Add space between buttons */}
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    style={buttonStyle2}
                    onClick={handleDeleteClick}
                  >
                    Delete User
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  {/* Add space between buttons */}
                  {userVerified ? (
                    <Button
                      variant="outlined"
                      // color="warning"
                      size="small"
                      style={{ ...buttonStyle3, ...unApproveColor }}
                      onClick={handleUnapprove}
                      className="UButton"
                    >
                      Unapprove
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      color="success"
                      size="small"
                      style={buttonStyle3}
                      onClick={handleApprove}
                    >
                      Approve
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <Chart
              aspect={3 / 1}
              title="History for created Articles"
              data={chartData}
            />
          </div>
        </div>
        {/* <div className="bottom">
          <h1 className="title">Article</h1> */}
        <ArticleTabe userId={userId} />
        {/* </div> */}
      </div>

      <Popup open={isPasswordPopupOpen} onClose={handlePasswordClose} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
            display: "grid",
            justifyItems: "right",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <form
              style={{
                margin: "15px",
                width: "95%",
              }}
            >
              <div>
                <EditFormField
                  value={editPassword}
                  options={""}
                  type={"input"}
                  title={"Password:"}
                  valueSetter={setEditPassword}
                />
              </div>
            </form>
          </div>
          <div
            style={{
              maxWidth: "60%",
              padding: "10px",
            }}
          >
            <button onClick={handlePassword} type="button">
              Submit
            </button>
          </div>
        </div>
      </Popup>

      <Popup open={isEditPopupOpen} onClose={handleEditClose} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
            display: "grid",
            justifyItems: "right",
          }}
        >
          <div
            style={{
              width: "100%",
            }}
          >
            <form
              style={{
                margin: "15px",
                width: "95%",
              }}
            >
              <div>
                <EditFormField
                  value={editName}
                  options={""}
                  type={"input"}
                  title={"Username:"}
                  valueSetter={setEditName}
                />
              </div>
            </form>
          </div>
          <div
            style={{
              maxWidth: "60%",
              padding: "10px",
            }}
          >
            <button onClick={handleEdit} type="button">
              Submit
            </button>
          </div>
        </div>
      </Popup>

      <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
        <div
          style={{
            background: "transparent",
            padding: "20px",
            borderRadius: "4px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width: "70%",
              height: "50px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <button
              style={{
                width: "200px",
                backgroundColor: "crimson",
                color: "white",
              }}
              onClick={() => handleDeleteClosePopup1("Yes")}
            >
              Yes
            </button>
            <button
              style={{ width: "200px" }}
              onClick={() => handleDeleteClosePopup1("No")}
            >
              No
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};

export default SingleUser;
