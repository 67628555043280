import "./single.scss";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Chart from "../../components/chart/Chart";
import { Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import EditFormField from "../../components/UI/EditFormField";
import { buttonStyle } from "./Styles_button";

const CommercialAd = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [category, setCategory] = useState([]);
  const [status, setStatus] = useState();
  const [image, setImage] = useState("");
  const [photo, setPhoto] = useState([]);
  const [createdAt, setCreatedAt] = useState();
  const { adId } = useParams();
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [isEditPopupOpen, setIsEditPopupOpen] = useState(false);
  const [allInterests, setAllInterests] = useState([]);
  const [editImages, setEditImages] = useState([]);
  const [editTitle, setEditTitle] = useState([]);
  const [editCategory, setEditCategory] = useState([]);
  const [editLink, setEditLink] = useState([]);
  const [editDescription, setEditDescription] = useState([]);
  const [shownImage, setShownImage] = useState([]);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [isMaxImages, setIsMaxImages] = useState(true);

  //
  const [fileList, setFileList] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);

  let visibleImages = [...editImages, ...selectedImages];
  useEffect(() => {
    setIsMaxImages(visibleImages.length > 0);
  }, [visibleImages]);
  const inputFile = useRef(null);

  const onImageClick = () => {
    inputFile.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (file && file.type.match(/image\/(png|jpg|jpeg)/gm)) {
      setFileList((oldFileList) => [...oldFileList, file]);
      setSelectedImages((oldImages) => [
        ...oldImages,
        URL.createObjectURL(file),
      ]);
    } else {
      alert("Selected file is not a valid image!");
    }
  };
  //

  const handleOpenPopup = (id) => {
    setShownImage(id);
    setIsImagePopupOpen(true);
  };
  const handleClosePopup = () => {
    setIsImagePopupOpen(false);
  };

  const getData = async () => {
    const url = `https://cdn.tauschtakel.de/admin-commercialAds/${adId}`;
    console.log(url);
    // const params = new URLSearchParams();
    // params.append("article", id);

    // const url2 = `https://cdn.tauschtakel.de/admin-likeHistory/articleHistory?article=${id}`;
    // const response2 = await fetch(url2);
    // const jsonLikedData = await response2.json();
    const response = await fetch(url);
    const data = await response.json();
    // let  = JSON.parse(text);
    setDescription(data.data.description);
    setImage(data.data.images[0]);
    setPhoto([...data.data.images]);
    setEditImages([...data.data.images]);
    setTitle(data.data.title);
    setLink(data.data.link);
    setCategory(data.data.category);
    setCreatedAt(data.data.createdAt);
    setStatus(data.data.status);
    setEditTitle(data.data.title);
    setEditCategory(data.data.category);
    setEditLink(data.data.link);
    setEditDescription(data.data.description);
  };

  useEffect(() => {
    getData();
    fetchInterests();
  }, [adId]);

  const buttonGroupStyle = {
    display: "flex",
  };

  const fetchInterests = async () => {
    const url = "https://cdn.tauschtakel.de/admin-interest/getAll";
    const response = await fetch(url);
    const data = await response.json();
    setAllInterests(data.data);
    setTimeout(() => {}, 3000);
  };

  const handleEditImagesRemove = (image) => {
    setEditImages((old) => {
      return old.filter((element) => element !== image);
    });
    setSelectedImages((old) => {
      return old.filter((element) => element !== image);
    });
  };

  const uploadEditImages = async () => {
    const data = new FormData();

    fileList.forEach((file, i) => {
      data.append("image", file, file.name);
    });

    const response = await fetch(
      "https://cdn.tauschtakel.de/user-article/postImage",
      {
        method: "POST",
        body: data,
      }
    );
    const uploadArray = await response.json();
    return uploadArray.response;
  };

  const handleEdit = async () => {
    // console.log(editCategory);
    if (visibleImages.length === 0) {
      return alert("Please select at least one image");
    }
    const uploadArray = await uploadEditImages();
    const resImage = editImages.concat(uploadArray);
    console.log(resImage, "kkkk");
    // return;
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", adId);
    urlencoded.append("title", editTitle);
    urlencoded.append("description", editDescription);
    urlencoded.append("category", editCategory);
    urlencoded.append("link", editLink);
    urlencoded.append("images", resImage);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-commercialAds/edit", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // setIsEditPopupOpen(false);
        // getData();
        // setSelectedImages([])
        // console.log(result);
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };

  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", adId);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };

    fetch(
      "https://cdn.tauschtakel.de/admin-commercialAds/delete",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        navigate("/commercialAd");
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeleteClick = () => {
    handleDeletePopup(adId);
  };
  const handleEditClick = () => {
    setIsEditPopupOpen(true);
  };
  const handleEditClose = () => {
    setIsEditPopupOpen(false);
  };
  const handleDeletePopup = () => {
    setIsDeletePopupOpen(true);
  };
  const handleDeleteClosePopup1 = (data) => {
    setIsDeletePopupOpen(false);
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  return (
    <div className="single">
      <Sidebar />
      <div className="singleContainer">
        <Navbar />
        <div className="top">
          <div className="left">
            <h1 className="title">Article Information</h1>
            <div className="item">
              <img src={image} alt="" className="itemImg" />
              <div className="details">
                <h1 className="itemTitle">{title} </h1>
                <div className="detailItem">
                  <span className="itemKey">Link:</span>
                  <span className="itemValue">{link}</span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Category:</span>
                  {console.log(category)}
                  <span className="itemValue">{category.join(", ")}</span>
                </div>
                {/* <div className="detailItem">
                  <span className="itemKey">Address:</span>
                  <span className="itemValue">{address} </span>
                </div> */}
                <div style={{ height: "20px" }}></div>
                <div className="detailItem">
                  <span className="itemKey">Online Since:</span>
                  <span className="itemValue">
                    {createdAt} <span className="itemValue"></span>
                  </span>
                </div>
                <div style={{ width: "10px", height: "10px" }}></div>{" "}
                {/* Button Section  */}
                <div style={buttonGroupStyle}>
                  <Button
                    variant="outlined"
                    color="info"
                    size="small"
                    style={buttonStyle}
                    onClick={() => handleEditClick(adId)}
                  >
                    Edit
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  {/* <div
                    className="deleteButton"
                    onClick={}
                  >
                    Delete
                  </div> */}
                  {/* <div style={{ width: "10px" }}></div>{" "} */}
                  <Button
                    variant="outlined"
                    color="error"
                    size="small"
                    style={buttonStyle}
                    onClick={() => handleDeleteClick(adId)}
                  >
                    Delete
                  </Button>
                  <div style={{ width: "10px" }}></div>{" "}
                  <div style={{ width: "10px" }}></div>{" "}
                  {/* Add space between buttons */}
                  <div style={{ width: "10px" }}></div>{" "}
                </div>
              </div>
            </div>
          </div>
          <div className="right">
            <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
              <div
                style={{ pointerEvents: isImagePopupOpen ? "none" : "auto" }}
              >
                <Popup open={isImagePopupOpen} onClose={handleClosePopup} modal>
                  <div
                    style={{
                      background: "transparent",
                      padding: "20px",
                      borderRadius: "4px",
                    }}
                  >
                    <img
                      style={{
                        width: "100%",
                        maxHeight: "80vh",
                        objectFit: "contain",
                      }}
                      src={shownImage}
                      alt="avatar"
                    />
                    <button onClick={handleClosePopup}>Close</button>
                  </div>
                </Popup>
              </div>
              <Popup open={isEditPopupOpen} onClose={handleEditClose} modal>
                <div
                  style={{
                    background: "transparent",
                    padding: "20px",
                    borderRadius: "4px",
                    display: "grid",
                    position: "relative",
                    // justifyItems: "right",
                  }}
                >
                  <div
                    style={{
                      marginBottom: "25px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "left",
                      // border: "1px solid black",
                      width: "100%",
                    }}
                  >
                    <form
                      style={{
                        margin: "15px",
                        marginTop: "0px",
                        display: "flex",
                        flexDirection: "column",
                        // alignItems: "center",
                        justifyContent: "left",
                        // minWidth: "50%",
                        // border: "1px solid black",
                      }}
                    >
                      <div
                        style={{
                          alignItems: "start",
                          // border: "1px solid black"
                        }}
                      >
                        <h1 style={{ color: "gray" }}>Commercial Ad</h1>
                      </div>
                      <div>
                        <EditFormField
                          value={editTitle}
                          options={""}
                          type={"input"}
                          title={"Title"}
                          valueSetter={setEditTitle}
                        />
                        <EditFormField
                          value={editLink}
                          options={""}
                          type={"input"}
                          title={"Link"}
                          valueSetter={setEditLink}
                        />
                        <EditFormField
                          value={editCategory}
                          options={allInterests}
                          type={"radio"}
                          title={"Category"}
                          valueSetter={setEditCategory}
                        />
                      </div>
                    </form>

                    <div
                      style={{
                        maxWidth: "50%",
                        // border: "1px solid black",
                        paddingLeft: "50px",
                        minWidth: "50%",
                      }}
                    >
                      {" "}
                      <div className="detailItem ">
                        {/* <span
                          style={{
                            fontSize: 23,
                            fontWeight: "bold",
                            color: "gray",
                          }}
                        >
                          Picture
                        </span> */}
                        <div className="grid-container">
                          {visibleImages && visibleImages.length > 0 ? (
                            visibleImages.map((image, index) => {
                              const removeImage = () => {
                                // console.log(index, "bbbbbbb");
                                handleEditImagesRemove(image);
                              };
                              return (
                                <div
                                  key={index}
                                  style={{ position: "relative" }}
                                >
                                  <img
                                    src={image}
                                    alt={`Image ${index}`}
                                    style={{
                                      width: "80px",
                                      height: "80px",
                                      borderRadius: "10px",
                                      marginRight: "10px",
                                    }}
                                  />
                                  <span
                                    style={{
                                      position: "absolute",
                                      marginTop: "5px",
                                      right: "20px",
                                    }}
                                  >
                                    <img
                                      style={{ width: "20px", height: "20px" }}
                                      src={require("../../components/Image/delete.png")}
                                      onClick={removeImage}
                                    />
                                  </span>
                                </div>
                              );
                            })
                          ) : (
                            <p>No images selected.</p>
                          )}
                          {isMaxImages ? (
                            ""
                          ) : (
                            <>
                              <img
                                style={{
                                  width: "80px",
                                  position: "relative",
                                }}
                                src={require("../../components/Image/camera.png")}
                                onClick={onImageClick}
                              />
                              <input
                                type="file"
                                id="file"
                                ref={inputFile}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <EditFormField
                        value={editDescription}
                        options={""}
                        type={"textarea"}
                        title={"Description"}
                        valueSetter={setEditDescription}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      maxWidth: "60%",
                      // border: "1px solid black",
                      padding: "10px",
                      position: "absolute",
                      // left: "250px",
                      right: "25px",
                      bottom: "0px",
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setIsEditPopupOpen(false);
                      }}
                      type="button"
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid",
                        borderColor: "#20ac73",
                        color: "inherit",
                      }}
                    >
                      Close
                    </button>
                    <button onClick={handleEdit} type="button">
                      Submit
                    </button>
                  </div>
                </div>
              </Popup>

              <Popup
                open={isDeletePopupOpen}
                onClose={handleDeleteClosePopup1}
                modal
              >
                <div
                  style={{
                    background: "transparent",
                    padding: "20px",
                    borderRadius: "4px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      width: "70%",
                      height: "50px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <button
                      style={{
                        width: "200px",
                        backgroundColor: "crimson",
                        color: "white",
                      }}
                      onClick={() => handleDeleteClosePopup1("Yes")}
                    >
                      Yes
                    </button>
                    <button
                      style={{ width: "200px" }}
                      onClick={() => handleDeleteClosePopup1("No")}
                    >
                      No
                    </button>
                  </div>
                </div>
              </Popup>
            </div>
            <Chart data={[]} aspect={3 / 1} title="History of Clicks" />
          </div>
        </div>
        <div style={{ height: "100%", paddingLeft: "20px" }}>
          <div className="detailItem">
            <span style={{ fontSize: 23, fontWeight: "bold", color: "gray" }}>
              Description :
            </span>
          </div>
          <div style={{ height: "10px" }}></div>
          <span className="itemValue">{description} </span>

          <div style={{ height: "40px" }}></div>

          <div className="detailItem">
            <span style={{ fontSize: 23, fontWeight: "bold", color: "gray" }}>
              Picture
            </span>
            <div style={{ display: "flex" }}>
              {photo && photo.length > 0 ? (
                photo.map((image, index) => {
                  // handleImageClick
                  const openImage = () => {
                    handleOpenPopup(image);
                  };
                  return (
                    <div key={index} style={{ position: "relative" }}>
                      <img
                        src={image}
                        onClick={openImage}
                        alt={`Image ${index}`}
                        style={{
                          width: "200px",
                          height: "200px",
                          borderRadius: "10px",
                          marginRight: "10px",
                        }}
                      />
                    </div>
                  );
                })
              ) : (
                <p>No images available.</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommercialAd;
