import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";

const ReportUserTable = () => {
  const [data, setData] = useState([]);
  const [id, setId] = useState([]);

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  const [apiDataIncrement, setApiDataIncrement] = useState(false);

  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const handleDeleteClosePopup1 = (data) => {
    if (data === "Yes") {
      handleDeleteClosePopup();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleDeleteClosePopup = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    if (apiDataIncrement) {
      urlencoded.append("increment", apiDataIncrement);
    }
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-userReport/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log(result.status);
        fetchData();
        setIsDeletePopupOpen(false);
      })
      .catch((error) => console.log("error", error));
  };

  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log(id[0]);
  };
  const handleOpenUserPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log("------->",id);
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const fetchData = async () => {
    const url = "https://cdn.tauschtakel.de/admin-userReport/getAll";
    const response = await fetch(url);
    const data = await response.json();
    setData(data.data);
    console.log(data.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        const handleDeleteAndIncrementClick = () => {
          setApiDataIncrement(true);
          // just making sure that the state is updated as expected before the following runs
          setTimeout(() => {
            handleDeletePopup(params.row._id);
          }, 0);
        };
        return (
          <div className="cellAction">
            <Link
              className="viewButton"
              style={{ textDecoration: "none" }}
              to={`/reportuser/${params.row._id}`}
            >
              View
            </Link>
            <div
              className="deleteButton"
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteClick(params.row._id)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Ignore
            </div>
            <div
              className="ABCutton"
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteAndIncrementClick(params.row._id)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Accept
            </div>
          </div>
        );
      },
    },
  ];

  const UserReportSource = [
    {
      field: "reporter",
      headerName: "Username",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.reporterData?.image);
        };
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image[0]} alt="avatar" />{" "} */}
            <img
              className="cellImg"
              src={params.row.reporterData?.image}
              alt="avatar"
              onClick={handleImageClick}
            />
            <Link
              to={`/users/${params.row.reporterData?._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.reporterData?.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "reportedPerson",
      headerName: "Reported User",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.reportedData?.image);
        };
        return (
          <div className="cellWithImg">
            {" "}
            {/* <img className="cellImg" src={params.row.image[0]} alt="avatar" />{" "} */}
            <img
              className="cellImg"
              src={params.row.reportedData?.image}
              alt="avatar"
              onClick={handleImageClick}
            />
            <Link
              to={`/users/${params.row.reportedData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.reportedData.username}
            </Link>
          </div>
        );
      },
    },
    {
      field: "title",
      headerName: "Title",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenUserPopup(params.row.userData.image);
          // console.log("User",params.row.userData.image);
        };
        return (
          <div className="cellWithImg">
            <p style={{ textDecoration: "none", color: "gray" }}>
              {params.row.headline}
            </p>
          </div>
        );
      },
    },
    {
      field: "description",
      headerName: "Description",
      width: 230,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenUserPopup(params.row.userData.image);
          // console.log("User",params.row.userData.image);
        };
        return (
          <div className="cellWithImg">
            <p
              to={params.row.description}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.description}
            </p>
          </div>
        );
      },
    },
    {
      field: "Created-At",
      headerName: "Created At",
      width: 200,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.createdAt} </div>;
      },
    },
  ];

  return (
    <>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        {/* <h1>My Component</h1>
  <button onClick={handleOpenPopup}>Open Popup</button> */}

        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <div className="datatable">
        <div className="datatableTitle">User Reports</div>
        <DataGrid
          className="datagrid"
          rows={data}
          columns={UserReportSource.concat(actionColumn)}
          pageSize={50}
          rowsPerPageOptions={[50]}
          getRowId={(row) => row._id}
        />
      </div>
    </>
  );
};

export default ReportUserTable;
