import "./sidebar.scss";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import StoreIcon from "@mui/icons-material/Store";
import AppsIcon from "@mui/icons-material/Apps";
import LogoutIcon from "@mui/icons-material/Logout";
import SettingsApplicationsIcon from "@mui/icons-material/SettingsApplications";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChildFriendlyIcon from "@mui/icons-material/ChildFriendly";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import MailOutlinedIcon from "@mui/icons-material/MailOutlined";
import SettingsSystemDaydreamOutlinedIcon from "@mui/icons-material/SettingsSystemDaydreamOutlined";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Link } from "react-router-dom";
import { DarkModeContext } from "../../context/darkModeContext";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { useContext } from "react";
import logo from "../../assets/sidebar_logo.png";
import Logout from "../../util/logout";
import HandshakeIcon from "@mui/icons-material/Handshake";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import ShareIcon from '@mui/icons-material/Share';

const Sidebar = () => {
  const { dispatch } = useContext(DarkModeContext);
  return (
    <div className="sidebar">
      <div className="top">
        <Link to="/home" style={{ textDecoration: "none" }}>
          <span className="logo">Admin Panel</span>
        </Link>
      </div>
      <hr />
      <div className="center">
        <ul>
          <p className="title">MAIN</p>
          <li>
            <DashboardIcon className="icon" />
            <Link to="/home" style={{ textDecoration: "none" }}>
              <span>Dashboard</span>
            </Link>
          </li>
          <p className="title">LISTS</p>
          <Link to="/users" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>Users</span>
            </li>
          </Link>
          <Link to="/interest" style={{ textDecoration: "none" }}>
            <li>
              <AppsIcon className="icon" />
              <span>Interest</span>
            </li>
          </Link>
          <Link to="/deal" style={{ textDecoration: "none" }}>
            <li>
              <AutorenewIcon className="icon" />
              <span>Exchange Deals</span>
            </li>
          </Link>
          <Link to="/dealMoney" style={{ textDecoration: "none" }}>
            <li>
              <AttachMoneyIcon className="icon" />
              <span>Money Deals</span>
            </li>
          </Link>
          <Link to="/article" style={{ textDecoration: "none" }}>
            <li>
              <ChildFriendlyIcon className="icon" />
              <span>All Articles</span>
            </li>
          </Link>
          <Link to="/pendingarticle" style={{ textDecoration: "none" }}>
            <li>
              <HourglassTopIcon className="icon" />
              <span>Pending Articles</span>
            </li>
          </Link>
          <Link to="/shares" style={{ textDecoration: "none" }}>
            <li>
            <ShareIcon className="icon" />
              <span>Shared Articles</span>
            </li>
          </Link>
          <Link to="/reportarticle" style={{ textDecoration: "none" }}>
            <li>
              <ReportGmailerrorredIcon className="icon" />
              <span>Article Report</span>
            </li>
          </Link>
          <Link to="/reportuser" style={{ textDecoration: "none" }}>
            <li>
              <ReportGmailerrorredIcon className="icon" />
              <span>User Reports</span>
            </li>
          </Link>
          <p className="title">COMMUNICATION</p>
          <Link to="/notification" style={{ textDecoration: "none" }}>
            <li>
              <NotificationsNoneIcon className="icon" />
              <span>Send Notifications</span>
            </li>
          </Link>
          <Link to="/newsletter" style={{ textDecoration: "none" }}>
            <li>
              <MailOutlinedIcon className="icon" />
              <span>Send Newsletter</span>
            </li>
          </Link>
          <p className="title">INCOME</p>
          <Link to="/commercialAd" style={{ textDecoration: "none" }}>
            <li>
              <AttachMoneyIcon className="icon" />
              <span>Commercial Ads</span>
            </li>
          </Link>
          <p className="title">BACKUP</p>
          <Link to="/backup" style={{ textDecoration: "none" }}>
            <li>
              <CloudDownloadIcon className="icon" />
              <span>Database</span>
            </li>
          </Link>
          <p className="title">USER</p>

          <Link to="/admin" style={{ textDecoration: "none" }}>
            <li>
              <AccountCircleOutlinedIcon className="icon" />
              <span>Admin Setting</span>
            </li>
          </Link>
          {/* <Link to="/" style={{ textDecoration: "none" }}> */}
          <li
            onClick={() => {
              Logout();
            }}
          >
            <LogoutIcon className="icon" />
            <span>Logout</span>
          </li>
          {/* </Link> */}
        </ul>
      </div>
      <div
        style={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
        className="bottom"
      >
        {/* <div
          className="colorOption"
          onClick={() => dispatch({ type: "LIGHT" })}
        ></div>
        <div
          className="colorOption"
          onClick={() => dispatch({ type: "DARK" })}
        ></div> */}
        <div
          style={{
            // backgroundColor: "white",
            padding: "4%",
            width: "50%",
            position: "absolute",
            // right: "0",
            top: "0",
            borderRadius: "30px",
          }}
        >
          <img width="100%" src={logo} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
