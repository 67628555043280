import "./datatable.scss";
import { DataGrid } from "@mui/x-data-grid";
// import { userColumns } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { ArticleColumns } from "../table/articletablesource";
import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import { showLoadingModal } from "../../util/showLoadingModal";
const ArticleTable = ({ userId }) => {
  const [data, setData] = useState([]);
  const [title, setTile] = useState("Articles");
  const [id, setId] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false);
  //
  const [currentPage, setCurrentPage] = useState(1);
  const [totalRows, setTotalRows] = useState(0);
  const handlePageChange = (newPage) => {
    if (newPage >= currentPage) {
      setCurrentPage(newPage + 1);
      fetchData({ page: newPage + 1 });
    }
  };
  //
  const handleOpenPopup = (id) => {
    setIsPopupOpen(true);
    setId(id[0]);
    // console.log(id[0]);
  };
  const handleOpenUserPopup = (id) => {
    setIsPopupOpen(true);
    setId(id);
    // console.log("------->",id);
  };
  const handleApprove = async (id) => {
    showLoadingModal();
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-article/approve", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("Approve", result);
        // fetchData();
        window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeleteClosePopup1 = (data) => {
    console.log(totalRows, "dddddd");
    if (data === "Yes") {
      handleDelete();
    } else {
      setIsDeletePopupOpen(false);
    }
  };
  const handleUnapprove = async (id) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    //test
    fetch("https://cdn.tauschtakel.de/admin-article/unApprove", requestOptions)
      .then((response) => response.text())
      .then((result) => window.location.reload())
      .catch((error) => console.log("error", error));
  };
  const handlePending = async (id) => {
    const url = "https://cdn.tauschtakel.de/admin-article/pendingArticles";
    const response = await fetch(url);
    const text = await response.text();
    let data = JSON.parse(text);
    setData(data.articles);
    setTile("Pending Article");
  };
  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };
  const handleDelete = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("id", id);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      redirect: "follow",
    };
    fetch("https://cdn.tauschtakel.de/admin-article/delete", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        // console.log("------->", result);
        // fetchData();
        window.location.reload();
        setIsDeletePopupOpen(false);
      })
      .catch((error) => console.log("error", error));
  };
  const handleDeletePopup = (id) => {
    setIsDeletePopupOpen(true);
    setId(id);
  };
  const fetchData = async ({ page }) => {
    setCurrentPage((old) => {
      // console.log(old, "from func");
      return old++;
    });
    let url = `https://cdn.tauschtakel.de/admin-article/allArticles?page=${page}`;
    if (userId != null) {
      url = `https://cdn.tauschtakel.de/admin-article/userArticles?page=${1}&uid=${userId}`;
    }
    // console.log(url);
    const response = await fetch(url);
    const data = await response.json();
    setData((old) => {
      return [...old, ...data.articles];
    });
    setTotalRows(data.totalRows);
  };
  const actionColumn = [
    {
      field: "action",
      headerName: "Action",
      width: 250,
      disableColumnMenu: true,
      sortable: false,
      renderCell: (params) => {
        const handleDeleteClick = () => {
          handleDeletePopup(params.row._id);
        };
        return (
          <div className="cellAction">
            <Link
              to={`/article/${params.row._id}`}
              style={{ textDecoration: "none" }}
            >
              <div className="viewButton">View</div>
            </Link>
            <div
              className="deleteButton"
              // onClick={() => handleDelete(params.row._id)}
              onClick={() => handleDeleteClick(params.row._id)}
              style={{ textAlign: "center", padding: "2px" }}
            >
              Delete
            </div>
            {params.row.status === "pending" ? (
              <div
                className="ABCutton"
                onClick={() => handleApprove(params.row._id)}
              >
                Approve
              </div>
            ) : params.row.status === "approved" ? (
              <div
                className="UButton"
                onClick={() => handleUnapprove(params.row._id)}
              >
                Unapprove
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    fetchData({ page: 1 });
  }, []);
  const ArticleColumns = [
    {
      field: "title",
      headerName: "Title",
      width: 230,
      sortable: true,
      renderCell: (params) => {
        const handleImageClick = () => {
          handleOpenPopup(params.row.image);
          // console.log("Title",params.row.image);
        };
        return (
          <div className="cellWithImg">
            {" "}
            <img
              className="cellImg"
              src={params.row.image[0]}
              alt="avatar"
              onClick={handleImageClick}
            />
            <Link
              to={`/article/${params.row._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.title}
            </Link>
          </div>
        );
      },
    },
    {
      field: "Price",
      headerName: "Price",
      width: 110,
      sortable: true,
      valueGetter: (params) => Number(params.row.price),
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.price} </div>;
      },
    },
    {
      field: "Share",
      headerName: "Share",
      width: 110,
      sortable: true,
      valueGetter: (params) => Number(params.row.shareCount),
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.shareCount} </div>;
      },
    },
    {
      field: "Category",
      headerName: "Category",
      width: 150,
      sortable: true,
      valueGetter: (params) => params.row.category,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.category} </div>;
      },
    },
    {
      field: "Condition",
      headerName: "Condition",
      width: 200,
      sortable: true,
      valueGetter: (params) => params.row.condition,
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.condition} </div>;
      },
    },
    {
      field: "created",
      headerName: "Created At",
      width: 200,
      sortable: true,
      valueGetter: (params) => {
        const [day, month, yearHourMin] = params.row.createdAt.split(".");
        const [year, hourMin] = yearHourMin.split(" ");
        const [hour, min] = hourMin.split(":");
        return new Date(year, month - 1, day, hour, min);
      },
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.createdAt}</div>;
      },
    },
    {
      field: "Deleted Time",
      headerName: "Deleted Time",
      width: 200,
      sortable: true,
      valueGetter: (params) => {
        const timeParts = params.row.timeRemaining.split(" ");
        let totalMinutes = 0;

        timeParts.forEach((part) => {
          const value = parseInt(part);
          if (part.includes("j")) {
            totalMinutes += value * 525600; // 1 Jahr = 525600 Minuten
          } else if (part.includes("mo")) {
            totalMinutes += value * 43800; // 1 Monat = 43800 Minuten
          } else if (part.includes("w")) {
            totalMinutes += value * 10080; // 1 Woche = 10080 Minuten
          } else if (part.includes("st")) {
            totalMinutes += value * 60; // 1 Stunde = 60 Minuten
          } else if (part.includes("m")) {
            totalMinutes += value; // 1 Minute = 1 Minute
          }
        });

        return totalMinutes;
      },
      renderCell: (params) => {
        return <div className="cellWithImg"> {params.row.timeRemaining} </div>;
      },
    },
  ];
  const index = 1;
  if (userId == null) {
    ArticleColumns.splice(index, 0, {
      field: "Username",
      headerName: "Username",
      width: 188,
      sortable: true,
      valueGetter: (params) => params.row.userData.username,
      renderCell: (params) => {
        const handleImageClick1 = () => {
          handleOpenUserPopup(params.row.userData.image);
          // console.log("User",params.row.userData.image);
        };
        return (
          <div className="cellWithImg">
            <img
              className="cellImg"
              src={params.row.userData.image}
              alt="avatar"
              onClick={handleImageClick1}
            />
            <Link
              to={`/users/${params.row.userData._id}`}
              style={{ textDecoration: "none", color: "gray" }}
            >
              {params.row.userData.username}
            </Link>
          </div>
        );
      },
    });
  }
  return (
    <div className="datatable">
      <div className="datatableTitle">All Articles</div>
      <div style={{ pointerEvents: isPopupOpen ? "none" : "auto" }}>
        <Popup open={isPopupOpen} onClose={handleClosePopup} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
            }}
          >
            <img
              style={{ width: "100%", maxHeight: "80vh", objectFit: "contain" }}
              src={id}
              alt="avatar"
            />
            <button onClick={handleClosePopup}>Close</button>
          </div>
        </Popup>
      </div>
      <div style={{ pointerEvents: isDeletePopupOpen ? "none" : "auto" }}>
        <Popup open={isDeletePopupOpen} onClose={handleDeleteClosePopup1} modal>
          <div
            style={{
              background: "transparent",
              padding: "20px",
              borderRadius: "4px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                width: "70%",
                height: "50px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <button
                style={{
                  width: "200px",
                  backgroundColor: "crimson",
                  color: "white",
                }}
                onClick={() => handleDeleteClosePopup1("Yes")}
              >
                Yes
              </button>
              <button
                style={{ width: "200px" }}
                onClick={() => handleDeleteClosePopup1("No")}
              >
                No
              </button>
            </div>
          </div>
        </Popup>
      </div>
      <DataGrid
        className="datagrid"
        rows={data}
        columns={ArticleColumns.concat(actionColumn)}
        pageSize={50}
        rowsPerPageOptions={[50]}
        getRowId={(row) => row._id}
        onPageChange={handlePageChange}
        rowCount={totalRows}
      />
    </div>
  );
};
export default ArticleTable;
